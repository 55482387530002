import Alert from 'react-bootstrap/Alert'

const Message = ({ balance }) => {
    return (
      <div>
        <div className="custom-alert custom-alert-info">
          <span className="balance-display">Balance: {balance} USDC</span>
        </div>
      </div>
    )
}

export default Message
